<template>
  <div class="social-login mb-3">
    <div class="mb-7 d-flex" style="position:relative">
      <div style="position: absolute;left: 46%;top: -10px;background: white;
        padding-left: 5px;padding-right: 5px;">{{$t('or')}}</div>
      <v-divider ></v-divider>
    </div>
    <!-- <v-row>
      <v-col>
        <v-btn
          outlined
          rounded
          class="btn-social"
          @click="AuthProvider('facebook')"
          block
        >
          <img src="@/assets/icons/facebook.svg" height="20px"/>
          <span>{{facebookText}}</span>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-btn
          outlined
          rounded
          class="btn-social"
          @click="AuthProvider('google')"
          block
        >
          <img src="@/assets/icons/google.svg" height="20px"/>
          <span>{{googleText}}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showAppleLogin">
      <v-col>
        <v-btn
          outlined
          rounded
          class="btn-social pl-3"
          @click="AuthProvider('apple')"
          block
        >
          <img src="@/assets/icons/apple.svg" height="23px" class="mb-1"/>
          <span>{{appleText}}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.btn-social{
  color:rgba(0, 0, 0, 0.6) !important;
  border-color:#b0b0b0;
  display:flex;
  justify-content: center !important;
  text-align:center;
  align-items: center;
  text-transform: none;
  img{
    // margin-right:100px;
    position:absolute;
    left: 0;
  }
  &:hover{
    border-color:#222222;
    color:#484848 !important;
  }
}
</style>
<script>
export default {
  computed: {
    facebookText() {
      if (this.$route.name === 'Register') {
        return this.$t('Register with Facebook');
      }
      return this.$t('Login with Facebook');
    },
    googleText() {
      if (this.$route.name === 'Register') {
        return this.$t('Register with Google');
      }
      return this.$t('Login with Google');
    },
    appleText() {
      if (this.$route.name === 'Register') {
        return this.$t('Register with Apple');
      }
      return this.$t('Login with Apple');
    },
    showAppleLogin() {
      return false;
      // return true;
      // return (
      //   window.navigator
      //   && window.navigator.userAgent
      //   && window.navigator.userAgent.includes('cordova-app-ios')
      // );
    },
  },
  methods: {
    AuthProvider(provider) {
      if (
        (window.navigator && window.navigator.userAgent && window.navigator.userAgent.includes('cordova-app'))
        && provider === 'google'
      ) {
        window.location = `${process.env.VUE_APP_APP_URL}/api/social/redirect/${provider}`;
      } else if (
        (window.navigator && window.navigator.userAgent && window.navigator.userAgent.includes('cordova-app-ios'))
        && provider === 'apple'
        // provider === 'apple'
      ) {
        window.location = `${process.env.VUE_APP_APP_URL}/api/social/redirect/${provider}`;
      } else {
        const self = this;
        this.$auth.authenticate(provider)
          .then((response) => {
            self.SocialLogin(provider, response);
          }).catch((err) => {
            console.log({ err });
          });
      }
    },
    SocialLogin(provider, response) {
      this.$http.post(`/sociallogin/${provider}`, response)
        .then(({ data: loginResponse }) => {
          this.$store.dispatch('loginUser', loginResponse);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
  },
};
</script>
